export default function (congressID) {
  return [
    {
      title: 'Dashboard',
      icon: 'HomeIcon',
      route: {
        name: 'congress-edit',
        params: {
          id: congressID,
        },
      },
      authority: 'Congress_Management_Congress_Edit',
    },
    {
      title: 'Program Building',
      icon: 'FileTextIcon',
      children: [
        {
          title: 'Registration Periods',
          icon: 'CalendarIcon',
          route: {
            name: 'congress-registration-periods',
            params: {
              id: congressID,
            },
          },
          authority: 'General_Registration_Period_View',
        },
        {
          title: 'Various Services',
          icon: 'SettingsIcon',
          route: {
            name: 'congress-services',
            params: {
              id: congressID,
            },
          },
          authority: 'Congress_Management_Congress_Service_View',
        },
        {
          title: 'Video Gallery',
          icon: 'FilmIcon',
          route: {
            name: 'congress-videos',
            params: {
              id: congressID,
            },
          },
          authority: 'Congress_Management_Video_View',
        },
        {
          title: 'Photo Gallery',
          icon: 'ImageIcon',
          route: {
            name: 'congress-photos',
            params: {
              id: congressID,
            },
          },
          authority: 'Congress_Management_Image_View',
        },
        {
          title: 'Topics',
          icon: 'ImageIcon',
          route: {
            name: 'congress-topics',
            params: {
              id: congressID,
            },
          },
          authority: 'Congress_Management_Congress_Topic_View',
        },
      ],
    },
    // {
    //   title: 'Sponsors',
    //   icon: 'GiftIcon',
    //   route: {
    //     name: 'congress-sponsors',
    //     params: {
    //       id: congressID,
    //     },
    //   },
    // },
    // {
    //   title: 'Sessions',
    //   icon: 'MonitorIcon',
    //   route: {
    //     name: 'congress-sessions',
    //     params: {
    //       id: congressID,
    //     },
    //   },
    // },
    {
      title: 'Sessions',
      icon: 'MonitorIcon',
      children: [
        {
          title: 'Session',
          icon: 'MonitorIcon',
          route: {
            name: 'congress-sessions',
            params: {
              id: congressID,
            },
          },
          authority: 'Congress_Management_Congress_Session_View',
        },
        {
          title: 'Rooms',
          icon: 'ImageIcon',
          route: {
            name: 'congress-rooms',
            params: {
              id: congressID,
            },
          },
          authority: 'Congress_Management_Congress_Room_View',
        },
        {
          title: 'Faculties',
          icon: 'UsersIcon',
          route: {
            name: 'congress-faculties',
            params: {
              id: congressID,
            },
          },
          authority: 'Session_Management_Session_Participant_View',
        },
      ],
    },
    // {
    //   title: 'Abstracts',
    //   icon: 'BookOpenIcon',
    //   route: {
    //     name: 'congress-abstracts',
    //     params: {
    //       id: congressID,
    //     },
    //   },
    // },
    // {
    //   title: 'Registrations',
    //   icon: 'CreditCardIcon',
    //   route: {
    //     name: 'congress-registrations',
    //     params: {
    //       id: congressID,
    //     },
    //   },
    //   authority: 'Congress_Management_Registration_View',
    // },
    // {
    //   title: 'E-mails',
    //   icon: 'InboxIcon',
    //   children: [
    //     {
    //       title: 'Sessions Details',
    //       route: {
    //         name: 'congress-emails-sessions-details',
    //         params: {
    //           id: congressID,
    //         },
    //       },
    //     },
    //     {
    //       title: 'Speaker Invitation',
    //       route: {
    //         name: 'congress-emails-speaker-invitation',
    //         params: {
    //           id: congressID,
    //         },
    //       },
    //     },
    //     {
    //       title: 'Payment Reminder',
    //       route: {
    //         name: 'congress-emails-payment-reminder',
    //         params: {
    //           id: congressID,
    //         },
    //       },
    //     },
    //   ],
    // },
  ]
}
