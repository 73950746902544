import { isAdmin, getUserRights } from '@/auth/utils'
import { members, admins } from '@/navigation/menuLinks'

const rights = getUserRights()
const menuLinks = isAdmin() ? admins : members
const pages = [{
  title: 'Website',
  href: process.env.VUE_APP_WEBSITE_URL,
  icon: 'GlobeIcon',
  isBookmarked: true,
}]

menuLinks.forEach(page => {
  if (page.route && (!page.authority || rights.includes(page.authority))) {
    pages.push(page)
  }
  if (page.children) {
    page.children.forEach(child => {
      if (child.route && (!child.pauthority || rights.includes(child.authority))) {
        pages.push(child)
      }
    })
  }
})

/* eslint-disable */
// prettier-ignore
export default {
  pages: {
    key: 'title',
    data: pages,
  },
}
/* eslint-enable */
