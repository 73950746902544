export const members = [
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    route: 'dashboard',
  },
  {
    title: 'Profile',
    icon: 'UserIcon',
    route: 'account-settings',
  },
  {
    title: 'Voting',
    icon: 'MailIcon',
    route: 'voting-list',
    authority: 'Poll_Management_Answer_View',
  },
  {
    title: 'Results',
    icon: 'PieChartIcon',
    route: 'votes-list',
    authority: 'Poll_Management_Poll_View',
  },
  {
    title: 'Congresses',
    icon: 'TrelloIcon',
    route: 'congresses-list',
    authority: 'Congress_Management_Congress_View',
  },
  {
    title: 'Videos',
    icon: 'FilmIcon',
    route: 'videos-list',
    authority: 'Congress_Management_Video_View',
  },
  {
    title: 'Questionnaire',
    icon: 'PenToolIcon',
    route: 'submissions-list',
    authority: 'Questionnaire_Management_Answer_View',
  },
  {
    title: 'Subscription',
    icon: 'GiftIcon',
    route: 'subscriptions',
    authority: 'Users_Subscriptions_View',
  },
  {
    title: 'Payments',
    icon: 'FileTextIcon',
    route: 'invoice-list',
    authority: 'Users_Subscriptions_View',
  },
]

export const admins = [
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    route: 'dashboard',
  },
  {
    title: 'Members',
    icon: 'UsersIcon',
    route: 'users-list',
    isBookmarked: true,
    authority: 'Users_User_View',
  },
  {
    title: 'Congresses',
    icon: 'TrelloIcon',
    children: [
      {
        title: 'Congresses',
        icon: 'TrelloIcon',
        route: 'congresses-list',
        isBookmarked: true,
        authority: 'Congress_Management_Congress_View',
      },
      {
        title: 'Sponsors',
        icon: 'BriefcaseIcon',
        route: 'sponsors-list',
        authority: 'General_Sponsor_View',
      },
      {
        title: 'Persons',
        icon: 'UserCheckIcon',
        route: 'persons-list',
        authority: 'Users_Participant_View',
      },
      // {
      //   title: 'Topics',
      //   icon: 'UserCheckIcon',
      //   route: 'topics-list',
      // },
    ],
  },
  {
    title: 'Voting',
    icon: 'MailIcon',
    children: [
      {
        title: 'Categories',
        route: 'vote-categories-list',
        authority: 'Poll_Management_Poll_View',
      },
      {
        title: 'Votes',
        route: 'votes-list',
        authority: 'Poll_Management_Poll_View',
      },
      {
        title: 'My Votes',
        route: 'voting-list',
        authority: 'Poll_Management_Answer_View',
      },
    ],
  },
  {
    title: 'Videos',
    icon: 'FilmIcon',
    route: 'videos-list',
    authority: 'Congress_Management_Video_View',
  },
  {
    title: 'Questionnaire',
    icon: 'PenToolIcon',
    children: [
      {
        title: 'Centers',
        route: 'questionnaire-centers-list',
        authority: 'Questionnaire_Management_Questionnaire_Edit',
      },
      {
        title: 'Categories',
        route: 'questionnaire-categories-list',
        authority: 'Questionnaire_Management_Category_View',
      },
      {
        title: 'Questionnaire Units',
        route: 'questionnaire-units-list',
        authority: 'Questionnaire_Management_Questionnaire_Edit',
      },
      {
        title: 'Questionnaires',
        route: 'questionnaires-list',
        authority: 'Questionnaire_Management_Questionnaire_Edit',
      },
      {
        title: 'Entries',
        route: 'answers-list',
        authority: 'Questionnaire_Management_Answer_View',
      },
      {
        title: 'My Entries',
        route: 'submissions-list',
        authority: 'Questionnaire_Management_Answer_View',
      },
    ],
  },
  {
    title: 'Main Parameters',
    icon: 'SettingsIcon',
    route: 'main-parameters-list',
    authority: 'General_MainParameter_View',
  },
  {
    title: 'Entities',
    icon: 'KeyIcon',
    children: [
      {
        title: 'Venues',
        route: 'venues-list',
        authority: 'General_Venue_View',
      },
      {
        title: 'Professions',
        route: 'professions-list',
        authority: 'General_Profession_View',
      },
      {
        title: 'Topics',
        route: 'topics-list',
        authority: 'General_Topic_View',
      },
      {
        title: 'Specialties',
        route: 'specialties-list',
        authority: 'General_Specialty_View',
      },
      {
        title: 'Registration Types',
        route: 'registration-types-list',
        authority: 'General_Registration_Type_View',
      },
      {
        title: 'Registration Periods',
        route: 'registration-periods-list',
        authority: 'General_Registration_Period_View',
      },
      {
        title: 'Session Types',
        route: 'session-types-list',
        authority: 'General_Session_Type_View',
      },
      // {
      //   title: 'Speaker Types',
      //   route: 'speaker-types-list',
      // },
      {
        title: 'Member Types',
        route: 'member-types-list',
        authority: 'General_MemberType_View',
      },
      {
        title: 'Prefectures',
        route: 'prefectures-list',
        authority: 'General_Prefecture_View',
      },
      {
        title: 'HospitalTypes',
        route: 'hospital-types-list',
        authority: 'General_HospitalType_View',
      },
      {
        title: 'Hospitals',
        route: 'hospitals-list',
        authority: 'General_Hospital_View',
      },
      {
        title: 'Clinics',
        route: 'clinics-list',
        authority: 'General_Clinic_View',
      },
      {
        title: 'Positions',
        route: 'job-positions-list',
        authority: 'General_JobPosition_View',
      },
      {
        title: 'Bank Accounts',
        route: 'bank-accounts-list',
        authority: 'Users_Payment_View',
      },
      {
        title: 'Working Groups',
        route: 'working-groups-list',
        authority: 'General_WorkingGroup_View',
      },
    ],
  },
  // {
  //   title: 'Reports',
  //   icon: 'PieChartIcon',
  //   route: 'reporting',
  // },
  {
    title: 'Administration',
    icon: 'ShieldIcon',
    children: [
      {
        title: 'Announcements',
        icon: 'BellIcon',
        route: 'announcements-list',
        isBookmarked: true,
        authority: 'General_Announcement_View',
      },
      {
        title: 'Post Categories',
        icon: 'ListIcon',
        route: 'post-categories-list',
        authority: 'General_Post_Category_View',
      },
      {
        title: 'User Groups',
        icon: 'UsersIcon',
        route: 'user-groups-list',
        authority: 'Users_User_Group_View',
      },
      {
        title: 'Member Requests',
        icon: 'FileTextIcon',
        route: 'user-requests-list',
        authority: 'Users_User_Request_View',
      },
    ],
  },
]
