<template>
  <b-nav-item-dropdown
    ref="dropdown"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="unreadNotifications"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t('Notifications') }}
        </h4>
        <b-badge v-if="unreadNotifications" pill variant="light-primary">
          {{ unreadNotifications }}
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-if="notifications.length"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
      @click.native.stop
    >
      <!-- Account Notification -->
      <b-media
        v-for="notification in notifications"
        :key="notification.id"
        class="position-relative"
      >
        <template #aside>
          <feather-icon
            v-if="!notification.url"
            icon="ShoppingCartIcon"
            variant="primary"
            size="32"
          />

          <b-link v-else :to="{ path: notification.url }">
            <feather-icon icon="ShoppingCartIcon" variant="primary" size="32" />
          </b-link>
        </template>

        <b-link v-if="notification.url" :to="{ path: '/' + notification.url }">
          <NotificationDropdownMessage :notification="notification" />
        </b-link>

        <template v-else>
          <NotificationDropdownMessage :notification="notification" />
        </template>

        <span
          class="cursor-pointer notification-close-icon ml-auto"
          @click="dismissNotification(notification.id)"
        >
          <feather-icon icon="XIcon" class="text-body" />
        </span>
      </b-media>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        :to="{ name: 'notifications' }"
        @click="closeDropdown"
      >
        {{ $t('Read all notifications') }}
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BBadge, BButton, BMedia, BLink } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import axios from '@/libs/axios'
import { ref } from '@vue/composition-api'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import NotificationDropdownMessage from '@/views/notifications/notifications-list/NotificationDropdownMessage.vue'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    VuePerfectScrollbar,
    BButton,
    FeatherIcon,
    NotificationDropdownMessage,
  },
  directives: {
    Ripple,
  },
  setup() {
    const notifications = ref([])
    const unreadNotifications = ref(0)

    const readNotifications = () => {
      unreadNotifications.value = 0
    }

    axios.get('notifications').then((res) => {
      notifications.value = res.data
      unreadNotifications.value = res.data.length
    })

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      notifications,
      unreadNotifications,
      perfectScrollbarSettings,
      readNotifications,
    }
  },
  data() {
    return {
      loading: {},
    }
  },
  methods: {
    closeDropdown() {
      this.readNotifications()
      this.$refs.dropdown.hide()
    },

    dismissNotification(id) {
      if (this.loading[id]) return
      this.loading[id] = true

      axios.get(`notifications/dismissed/${id}`).then(() => {
        this.notifications = this.notifications.filter((el) => el.id !== id)

        if (this.unreadNotifications <= 1) {
          this.unreadNotifications = 0
        } else {
          this.unreadNotifications -= 1
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.notification-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
