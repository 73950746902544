<template>
  <b-media
    v-if="entityData.title"
    class="congress-wrap"
    :class="{
      'px-0': horizontal,
    }"
  >
    <template v-if="entityData.logo" #aside>
      <b-img :src="entityData.logo" alt="logo" class="congress-logo" />
    </template>

    <div
      class="d-flex align-center justify-center"
      :class="{
        'flex-column': !horizontal,
      }"
    >
      <b-link class="font-weight-bolder mb-25" :to="`/congresses/edit/${entityData.id}`">
        {{ entityData.title }}
      </b-link>
      <span v-if="horizontal" class="mx-1">|</span>
      <span> {{ formatDate(entityData.startDate) }} - {{ formatDate(entityData.endDate) }} </span>
    </div>
  </b-media>
</template>

<script>
import { BImg, BMedia, BLink } from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'

export default {
  name: 'Logo',
  components: {
    BLink,
    BImg,
    BMedia,
  },
  props: {
    horizontal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    entityData() {
      return this.$store.getters['app-common/selectedEntity']
    },
  },
  methods: {
    formatDate,
  },
}
</script>

<style lang="scss" scoped>
.congress-wrap {
  padding: 0.65rem 1.24rem 0.35rem 1.64rem;
}

.congress-logo {
  max-width: 36px;
}
</style>
